import React from "react";
import { Link } from "react-router-dom";
import Blog from "../Items/Blog";



function Blogs({blogsData}) {
  return (
    <>
      <div className="row">
        {blogsData.map((blog) => (
          <div className="col-md-4" key={blog.id}>
            <Blog blog={blog} />
          </div>
        ))}
      </div>
      <div className="spacer" data-height="50"></div>
      <div className="text-center">
        <Link to="/blogs" className="btn btn-default">
          Show All Blogs
        </Link>
      </div>
    </>
  );
}

export default Blogs;
