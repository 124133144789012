export const educationData = [
  {
    id: 1,
    year: "2018 - 2022",
    degree: "Bachelor’s Degree",
    content: "BE Electrical Engineering (Computer Vision) - NUST",
  },
  {
    id: 2,
    year: "2016 - 2018",
    degree: "High School",
    content: "PakTurk Maarif International School",
  },
];
export const allData = [
  {
    id: 1,
    name: "HAE Landscape Consultants",
    category: ["web"],
    image: "images/portfolio/1.jpg",
    slug: "hae-consultancy",
  },
];
export const allPubs = [
  {
    id: 1,
    name: "Diagnosis and Prognosis of Diabetes Mellitus with Deep Learning",
    category: [
      "KNN",
      "Catboost",
      "Diabetes Mellitus",
      "Deep Learning",
      "xgboost",
      "LGBM",
      "healthcare",
    ],
    image: "images/portfolio/2.jpg",
    slug: "diabetes-mellitus-content",
  },
  {
    id: 2,
    name: "ML and DL Architectures Comparisons for the Classification of COVID-19 Using Chest X-Ray Images",
    category: [
      "medical imaging",
      "SVM",
      "CNN",
      "AlexNet",
      "X-rays",
      "Covid-19",
    ],
    image: "images/portfolio/3.jpg",
    slug: "covid-19-chest-content",
  },
];
export const blogsData = [
  {
    id: 1,
    title: "The Truth About Design In 3 Minutes",
    category: "Thoughts",
    featureImage: "images/blog/1.jpg",
    date: "13 March, 2021",
    author: "Bako Doe",
    slug: "the-truth-about-design",
  },
  {
    id: 2,
    title: "The Ugly Truth About Design",
    category: "Blog",
    featureImage: "images/blog/2.jpg",
    date: "13 March, 2021",
    author: "Bako Doe",
    slug: "the-ugly-truth-about-design",
  },
  {
    id: 3,
    title: "How To Become Better With UI Design",
    category: "Thoughts",
    featureImage: "images/blog/3.jpg",
    date: "13 March, 2021",
    author: "Bako Doe",
    slug: "how-to-become-better-with-ui-design",
  },
  {
    id: 4,
    title: "The Truth About Design In 3 Minutes",
    category: "Thoughts",
    featureImage: "images/blog/1.jpg",
    date: "13 March, 2021",
    author: "Bako Doe",
    slug: "the-truth-about-design",
  },
  {
    id: 5,
    title: "The Ugly Truth About Design",
    category: "Blog",
    featureImage: "images/blog/2.jpg",
    date: "13 March, 2021",
    author: "Bako Doe",
    slug: "the-ugly-truth-about-design",
  },
  {
    id: 6,
    title: "How To Become Better With UI Design",
    category: "Thoughts",
    featureImage: "images/blog/3.jpg",
    date: "13 March, 2021",
    author: "Bako Doe",
    slug: "how-to-become-better-with-ui-design",
  },
  {
    id: 7,
    title: "The Truth About Design In 3 Minutes",
    category: "Thoughts",
    featureImage: "images/blog/1.jpg",
    date: "13 March, 2021",
    author: "Bako Doe",
    slug: "the-truth-about-design",
  },
  {
    id: 8,
    title: "The Ugly Truth About Design",
    category: "Blog",
    featureImage: "images/blog/2.jpg",
    date: "13 March, 2021",
    author: "Bako Doe",
    slug: "the-ugly-truth-about-design",
  },
  {
    id: 9,
    title: "How To Become Better With UI Design",
    category: "Thoughts",
    featureImage: "images/blog/3.jpg",
    date: "13 March, 2021",
    author: "Bako Doe",
    slug: "how-to-become-better-with-ui-design",
  },
];
export const herosectionData = {
  name: "Adnan Karim",
  aboutMe:
    "Adnan Karim, a Data Scientist, Researcher and App Development Engineer with a passion for using technology to solve complex problems.",
};
export const aboutData = {
  cvpath: "media/empty.pdf",
  image: "images/logo150.png",
  name: "Adnan Karim",
  location: "Islamabad PK",
  birthday: "06 June, 2000",
  email: "email@adnankarim.dev",
  aboutMe:
    "Adnan Karim, a Data Scientist and App Development Engineer with a passion for using technology to solve complex problems.",
};

export const testimonialsData = [
  {
    id: 1,
    content:
      "I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck!",
    authorImage: "images/customer-1.png",
    authorName: "Helen Doe",
    authorStatus: "Themeforest customer",
  },
  {
    id: 2,
    content:
      "I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck!",
    authorImage: "images/customer-2.png",
    authorName: "Jane Doe",
    authorStatus: "Themeforest customer",
  },
  {
    id: 3,
    content:
      "I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck!",
    authorImage: "images/customer-2.png",
    authorName: "Jane Doe",
    authorStatus: "Themeforest customer",
  },
];
const clientsData = [
  {
    id: 1,
    image: "images/clients/1.png",
  },
  {
    id: 2,
    image: "images/clients/2.png",
  },
  {
    id: 3,
    image: "images/clients/3.png",
  },
  {
    id: 4,
    image: "images/clients/4.png",
  },
  {
    id: 5,
    image: "images/clients/2.png",
  },
];

export const funfactData = [
  {
    id: 1,
    title: "Projects completed",
    count: 157,
    icon: "icon-like",
  },
  {
    id: 2,
    title: "Cup of coffee",
    count: 2765,
    icon: "icon-cup",
  },
  {
    id: 3,
    title: "Happy customers",
    count: 350,
    icon: "icon-emotsmile",
  },
  {
    id: 4,
    title: "Awards won",
    count: 29,
    icon: "icon-trophy",
  },
];
export const skillData = {
  skillContent:
    "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarks grove right at the coast of the Semantics",
  progressData: [
    {
      id: 1,
      name: "React",
      percentage: 85,
    },
    {
      id: 2,
      name: "NextJS",
      percentage: 85,
    },
    {
      id: 3,
      name: "Gatsby",
      percentage: 85,
    },
    {
      id: 4,
      name: "HTML & CSS",
      percentage: 90,
    },
    {
      id: 5,
      name: "Python",
      percentage: 80,
    },
    {
      id: 6,
      name: "PyTorch",
      percentage: 80,
    },
  ],
};
