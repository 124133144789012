import React, { useState } from "react";
import { Bounce } from "react-activity";
import "react-activity/dist/library.css";
const contactData = {
  phone: [""],
  email: ["email@adnankarim.dev"],
  location: "Islamabad PK",
};
// 7d2d3910-37fe-11ed-8ff6-d1ee553f3964
const FORM_ENDPOINT =
  "https://public.herotofu.com/v1/7d2d3910-37fe-11ed-8ff6-d1ee553f3964";
function Contact() {
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const submitHandler = (event) => {
    event.preventDefault();
    setLoading(true)
    if (!formdata.name) {
      setError(true);
      setMessage("Name is required");
    } else if (!formdata.email) {
      setError(true);
      setMessage("Email is required");
    } else if (!formdata.subject) {
      setError(true);
      setMessage("Subject is required");
    } else if (!formdata.message) {
      setError(true);
      setMessage("Message is required");
    } else {
      setError(false);

      setMessage("");
      fetch(FORM_ENDPOINT, {
        method: "POST",

        headers: {
          Accept: "application/json",

          "Content-Type": "application/json",
        },

        body: JSON.stringify(formdata),
      })
        .then((response) => {
          // It's likely a spam/bot request, so bypass it to validate via captcha

          return response.json();
        })

        .then(() => setMessage("We'll be in touch soon."))

        .catch((err) => {
          setError(true);
          setMessage("Error in sending message!");
        });

      
    }
    setLoading(false)
  };

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  return (
    <div className="row">
      <div className="col-md-4 mb-4 mb-md-0">
        {/* <div className="contact-info mb-5">
          <i className="icon-phone"></i>
          <div className="details">
            <h5>Phone</h5>
            {contactData.phone.map((singlePhone, index) => (
              <span key={index}>{singlePhone}</span>
            ))}
          </div>
        </div> */}
        <div className="contact-info mb-5">
          <i className="icon-envelope"></i>
          <div className="details">
            <h5>Email address</h5>
            {contactData.email.map((singleEmail, index) => (
              <a href="mailto:email@adnankarim.dev">
                <span key={index}>{singleEmail}</span>
              </a>
            ))}
          </div>
        </div>
        <div className="contact-info">
          <i className="icon-location-pin"></i>
          <div className="details">
            <h5>Location</h5>
            <span>{contactData.location}</span>
          </div>
        </div>
      </div>

      <div className="col-md-8">
        <form className="contact-form" onSubmit={submitHandler}>
          <div className="row">
            <div className="column col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Your name"
                  onChange={handleChange}
                  value={formdata.name}
                />
              </div>
            </div>

            <div className="column col-md-6">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Email address"
                  onChange={handleChange}
                  value={formdata.email}
                />
              </div>
            </div>

            <div className="column col-md-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  placeholder="Subject"
                  onChange={handleChange}
                  value={formdata.subject}
                />
              </div>
            </div>

            <div className="column col-md-12">
              <div className="form-group">
                <textarea
                  name="message"
                  className="form-control"
                  rows="5"
                  placeholder="Message"
                  onChange={handleChange}
                  value={formdata.message}
                ></textarea>
              </div>
            </div>
          </div>
          {!loading ? (
            <button
              onClick={() => setLoading(true)}
              type="submit"
              name="submit"
              value="Submit"
              className="btn btn-default"
            >
              <i className="icon-paper-plane"></i>Send Message
            </button>
          ) : (
            <button
              className="btn btn-default"
            >
              <Bounce/>
              
            </button>
          )}
        </form>
        {handleAlerts()}
      </div>
    </div>
  );
}

export default Contact;
